import React from "react"
import Layout from "../components/layout";
import { Box, Typography } from "@material-ui/core";

const notFound = () => (
  <Layout id="notfound" title="not found">
    <Box color="white">
      <Typography variant="h2">Not Found</Typography>
      <Typography variant="body1">お探しのページは見つかりませんでした。</Typography>
    </Box>
  </Layout>
);

export default notFound;